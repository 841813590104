::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background-color: blue;
}

.language-toggle {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
  color: red;
  background-color: white;
  font-weight: 700;
  padding: 5px 15px;
}

.goto-next {
  border: 0;
  bottom: 0;
  display: block;
  height: 5em;
  left: 50%;
  margin: 0 0 0 -5em;
  overflow: hidden;
  position: absolute;
  text-indent: 10em;
  white-space: nowrap;
  width: 10em;
  z-index: 1;
}

.goto-next:before {
  background-image: url("https://cdn.icon-icons.com/icons2/1509/PNG/512/godown_104407.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  display: block;
  height: 1.5em;
  left: 50%;
  margin: -0.75em 0 0 -1em;
  position: absolute;
  top: 50%;
  width: 2em;
  z-index: 1;
}

.spotlight {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.spotlight:before {
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

#cero {
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;
}

#cero .container {
  position: relative;
  z-index: 2;
}

#cero .star {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: blue;
  top: -10px;
  animation: fall-blue linear infinite;
}

@keyframes fall-blue {
  to {
    transform: translateY(100vh);
  }
}

#cero .star:nth-child(1) {
  left: 10%;
  animation-duration: 3s;
}

#cero .star:nth-child(2) {
  left: 20%;
  animation-duration: 5s;
}

#cero .star:nth-child(3) {
  left: 30%;
  animation-duration: 7s;
}

#cero .star:nth-child(4) {
  left: 40%;
  animation-duration: 4s;
}

#cero .star:nth-child(5) {
  left: 50%;
  animation-duration: 6s;
}

#cero .star:nth-child(6) {
  left: 60%;
  animation-duration: 8s;
}

#cero .star:nth-child(7) {
  left: 70%;
  animation-duration: 5s;
}

#cero .star:nth-child(8) {
  left: 80%;
  animation-duration: 6s;
}

#cero .star:nth-child(9) {
  left: 90%;
  animation-duration: 4s;
}

#cero .star:nth-child(10) {
  left: 100%;
  animation-duration: 7s;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#one {
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;
}

#one .container {
  position: relative;
  z-index: 2;
}

#one .star {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: red;
  top: -10px;
  animation: fall linear infinite;
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}

#one .star:nth-child(1) {
  left: 10%;
  animation-duration: 3s;
}

#one .star:nth-child(2) {
  left: 20%;
  animation-duration: 5s;
}

#one .star:nth-child(3) {
  left: 30%;
  animation-duration: 7s;
}

#one .star:nth-child(4) {
  left: 40%;
  animation-duration: 4s;
}

#one .star:nth-child(5) {
  left: 50%;
  animation-duration: 6s;
}

#one .star:nth-child(6) {
  left: 60%;
  animation-duration: 8s;
}

#one .star:nth-child(7) {
  left: 70%;
  animation-duration: 5s;
}

#one .star:nth-child(8) {
  left: 80%;
  animation-duration: 6s;
}

#one .star:nth-child(9) {
  left: 90%;
  animation-duration: 4s;
}

#one .star:nth-child(10) {
  left: 100%;
  animation-duration: 7s;
}

.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.welcome h1 {
  color: #fff;
  text-align: center;
}

.spotlight {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.menu-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ithem-menu {
  background-color: red;
  text-decoration: none;
  color: #fff;
  padding: 5px 15px;
  border-radius: 10px;
  margin-top: 100px;
  border: 1px solid red;
  font-weight: 700;
  text-align: center;
}

.ithem-menu:hover {
  background-color: white;
  color: red;
  border: 1px solid red;
}

.texto-letreado {
  margin-top: 50px;
  text-align: justify;
  font-size: 20px;
}

#two {
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;
}

#two .container {
  position: relative;
  z-index: 2;
}

#two .star {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: green;
  top: -10px;
  animation: fall linear infinite;
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}

#two .star:nth-child(1) {
  left: 10%;
  animation-duration: 3s;
}

#two .star:nth-child(2) {
  left: 20%;
  animation-duration: 5s;
}

#two .star:nth-child(3) {
  left: 30%;
  animation-duration: 7s;
}

#two .star:nth-child(4) {
  left: 40%;
  animation-duration: 4s;
}

#two .star:nth-child(5) {
  left: 50%;
  animation-duration: 6s;
}

#two .star:nth-child(6) {
  left: 60%;
  animation-duration: 8s;
}

#two .star:nth-child(7) {
  left: 70%;
  animation-duration: 5s;
}

#two .star:nth-child(8) {
  left: 80%;
  animation-duration: 6s;
}

#two .star:nth-child(9) {
  left: 90%;
  animation-duration: 4s;
}

#two .star:nth-child(10) {
  left: 100%;
  animation-duration: 7s;
}

.container-back-menu {
  display: flex;
  justify-content: center;
}

.container-back-menu .back-menu {
  background-color: green;
  color: #fff;
  text-decoration: none;
  padding: 5px 15px;
  border: 1px solid green;
  border-radius: 10px;
  margin-top: 50px;
  font-weight: 700;
}

.container-back-menu .back-menu:hover {
  background-color: white;
  color: green;
}

@media (max-width: 576px) {
  .texto-letreado {
    margin-top: 0;
  }
}